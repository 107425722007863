var Swapper = (function (elementHandler, domChecker) {
    function swapperInstance(element1, element2, options, callback) {
        swapperInstance._swapper(element1, element2, options, callback);
    }
    return swapperInstance;
})();

Swapper._isNode = (function (NodeType, HTMLElementType) {
    return function (element) {
        if (!element) {
            return false;
        }
        try {
            return element instanceof NodeType || element instanceof HTMLElementType;
        } catch (error) { }
        if (typeof element !== "object") {
            return false;
        }
        if (typeof element.nodeType !== "number") {
            return false;
        }
        if (typeof element.nodeName !== "string") {
            return false;
        }
        return true;
    };
})(Node, HTMLElement);

Swapper._isInDOM = (function (isNodeFunction) {
    return function (element, skipCheck) {
        if (!skipCheck && !isNodeFunction(element)) {
            throw TypeError("element must be a DOM node, got " + element);
        }
        while ((element = element.parentNode)) {
            if (element === document) {
                return true;
            }
        }
        return false;
    };
})(Swapper._isNode);

Swapper._insertBefore = (function () {
    return function (newElement, referenceElement) {
        referenceElement.parentNode.insertBefore(newElement, referenceElement);
    };
})();

Swapper._insertAfter = (function () {
    return function (newElement, referenceElement) {
        var parent = referenceElement.parentNode;
        if (parent.lastChild === referenceElement) {
            parent.appendChild(newElement);
        } else {
            parent.insertBefore(newElement, referenceElement.nextSibling);
        }
    };
})();

Swapper._removeNode = (function () {
    return function (element) {
        if (element.parentNode) {
            element.parentNode.removeChild(element);
        }
    };
})();

Swapper._setTransform = (function () {
    return function (element, transformValue) {
        element.style["-webkit-transform"] = transformValue;
        element.style["-moz-transform"] = transformValue;
        element.style["-ms-transform"] = transformValue;
        element.style["-o-transform"] = transformValue;
        element.style.transform = transformValue;
    };
})();

Swapper._setTransition = (function () {
    return function (element, transitionValue) {
        if (transitionValue) {
            element.style["-webkit-transition"] = "-webkit-" + transitionValue;
            element.style["-moz-transition"] = "-moz-" + transitionValue;
            element.style["-ms-transition"] = "-ms-" + transitionValue;
            element.style["-o-transition"] = "-o-" + transitionValue;
            element.style.transition = transitionValue;
        } else {
            element.style["-webkit-transition"] = "";
            element.style["-moz-transition"] = "";
            element.style["-ms-transition"] = "";
            element.style["-o-transition"] = "";
            element.style.transition = "";
        }
    };
})();

Swapper._getStyles = (function (documentObject) {
    return function (element, useInlineStyles) {
        var styles;
        if (useInlineStyles) {
            styles = element.style;
        } else {
            styles = documentObject.defaultView.getComputedStyle(element, null);
        }
        return {
            "-webkit-transition": styles["-webkit-transition"],
            "-moz-transition": styles["-moz-transition"],
            "-ms-transition": styles["-ms-transition"],
            "-o-transition": styles["-o-transition"],
            transition: styles.transition,
            display: styles.display,
            opacity: styles.opacity,
            top: styles.top,
            left: styles.left,
            height: styles.height,
            width: styles.width,
            position: styles.position,
        };
    };
})(document);

Swapper._easings = {
    linear: "linear",
    ease: "ease",
    "ease-in": "ease-in",
    "ease-out": "ease-out",
    "ease-in-out": "ease-in-out",
    "step-start": "step-start",
    "step-end": "step-end",
};
Swapper._transitions = {
    fade: [{ fade: true }, { fade: true }],
    "fade-on": [{ fade: true }, {}],
    "fade-off": [{}, { fade: true }, true],
    "scale-in": [{ transform: "scale(0.01)" }, {}],
    "scale-out": [{}, { transform: "scale(0.01)" }, true],
    "rotate-left": [
        { transform: "rotateY(-180deg) perspective(360px)", fade: true },
        { transform: "rotateY( 180deg) perspective(360px)", fade: true },
    ],
    "rotate-right": [
        { transform: "rotateY( 180deg) perspective(360px)", fade: true },
        { transform: "rotateY(-180deg) perspective(360px)", fade: true },
    ],
    "cube-left": [
        { transform: "translate3d( 50%,0,0) rotateY(-90deg) perspective(360px)" },
        { transform: "translate3d(-50%,0,0) rotateY( 90deg) perspective(360px)" },
    ],
    "cube-right": [
        { transform: "translate3d(-50%,0,0) rotateY( 90deg) perspective(360px)" },
        { transform: "translate3d( 50%,0,0) rotateY(-90deg) perspective(360px)" },
    ],
    "swap-left": [
        { transform: "translate3d( 65%,0,0) rotateY( 90deg) perspective(360px)" },
        { transform: "translate3d(-65%,0,0) rotateY(-90deg) perspective(360px)" },
    ],
    "swap-right": [
        { transform: "translate3d(-65%,0,0) rotateY(-90deg) perspective(360px)" },
        { transform: "translate3d( 65%,0,0) rotateY( 90deg) perspective(360px)" },
    ],
    "explode-in": [{ fade: true, transform: "scale(1.25)" }, {}],
    "explode-out": [{}, { fade: true, transform: "scale(1.25)" }, true],
    "implode-in": [{}, { fade: true, transform: "scale(0.60)" }, true],
    "implode-out": [{ fade: true, transform: "scale(0.80)" }, {}],
    "slide-left": [
        { transform: "translate3d( 100%,0,0)" },
        { transform: "translate3d(-100%,0,0)" },
    ],
    "slide-right": [
        { transform: "translate3d(-100%,0,0)" },
        { transform: "translate3d( 100%,0,0)" },
    ],
    "slide-up": [
        { transform: "translate3d(0, 100%,0)" },
        { transform: "translate3d(0,-100%,0)" },
    ],
    "slide-down": [
        { transform: "translate3d(0,-100%,0)" },
        { transform: "translate3d(0, 100%,0)" },
    ],
    "slideon-left": [{ transform: "translate3d(-100%,0,0)" }, {}],
    "slideoff-left": [{}, { transform: "translate3d(-100%,0,0)" }, true],
    "slideon-right": [{ transform: "translate3d(100%,0,0)" }, {}],
    "slideoff-right": [{}, { transform: "translate3d(100%,0,0)" }, true],
    "slideon-up": [{ transform: "translate3d(0,-100%,0)" }, {}],
    "slideoff-up": [{}, { transform: "translate3d(0,-100%,0)" }, true],
    "slideon-down": [{ transform: "translate3d(0,100%,0)" }, {}],
    "slideoff-down": [{}, { transform: "translate3d(0,100%,0)" }, true],
    "slideon-left-ios": [
        { transform: "translate3d(100%,0,0)" },
        { transform: "translate3d(-30%,0,0)" },
    ],
    "slideoff-right-ios": [
        { transform: "translate3d(-30%,0,0)" },
        { transform: "translate3d(100%,0,0)" },
        true,
    ],
    "glideon-right": [
        { transform: "translate3d(110%,0,0)" },
        { transform: "translate3d(-20%,0,0)" },
    ],
    "glideoff-right": [
        { transform: "translate3d(-20%,0,0)" },
        { transform: "translate3d(110%,0,0)" },
        true,
    ],
    "glideon-left": [
        { transform: "translate3d(-110%,0,0)" },
        { transform: "translate3d(20%,0,0)" },
    ],
    "glideoff-left": [
        { transform: "translate3d(20%,0,0)" },
        { transform: "translate3d(-110%,0,0)" },
        true,
    ],
    "glideon-down": [
        { transform: "translate3d(0,110%,0)" },
        { transform: "translate3d(0,-20%,0)" },
    ],
    "glideoff-down": [
        { transform: "translate3d(0,-20%,0)" },
        { transform: "translate3d(0,110%,0)" },
        true,
    ],
    "glideon-up": [
        { transform: "translate3d(0,-110%,0)" },
        { transform: "translate3d(0,20%,0)" },
    ],
    "glideoff-up": [
        { transform: "translate3d(0,20%,0)" },
        { transform: "translate3d(0,-110%,0)" },
        true,
    ],
};
Swapper._validate = (function (isNodeFunction, transitionsList, easingsList) {
    return { element: validateElement, options: validateOptions, callback: validateCallback };

    function validateElement(element) {
        if (!isNodeFunction(element)) {
            throw TypeError("element must be a DOM node, got " + element);
        }
    }

    function validateOptions(options) {
        switch (typeof options) {
            case "string":
                options = { transition: options };
                break;
            case "undefined":
                options = {};
                break;
            case "object":
                break;
            default:
                throw TypeError("options must be an object if defined, got " + options);
        }

        switch (typeof options.transition) {
            case "string":
                if (!(options.transition in transitionsList) && options.transition !== "instant") {
                    throw TypeError(options.transition + " is not a valid transition");
                }
                break;
            case "undefined":
                break;
            default:
                throw TypeError(
                    "transition must be a string if defined, got " + options.transition
                );
        }

        switch (typeof options.duration) {
            case "number":
                if (options.duration < 0) {
                    throw TypeError(
                        "duration must be a non-negative integer, got " + options.duration
                    );
                }
                break;
            case "undefined":
                break;
            default:
                throw TypeError(
                    "duration must be a number if defined, got " + options.duration
                );
        }

        switch (typeof options.easing) {
            case "string":
                if (!(options.easing in easingsList) && options.easing.substr(0, 13) !== "cubic-bezier(") {
                    throw TypeError(options.easing + " is not a valid easing");
                }
                break;
            case "undefined":
                break;
            default:
                throw TypeError("easing must be a string if defined, got " + options.easing);
        }

        return options;
    }

    function validateCallback(callback) {
        switch (typeof callback) {
            case "undefined":
                callback = function () { };
                break;
            case "function":
                break;
            default:
                throw TypeError("callback must be a function if defined, got " + callback);
        }
        return callback;
    }
})(Swapper._isNode, Swapper._transitions, Swapper._easings);

Swapper._swapper = (function (
    isNode,
    isInDOM,
    insertBefore,
    insertAfter,
    removeNode,
    setTransform,
    setTransition,
    getStyles,
    transitions,
    easings,
    validate,
    windowObj,
    documentObj
) {
    var defaultTransform = "translate3d(0,0,0) scale(1)",
        defaultTransition = "fade",
        defaultEasing = "ease-in-out";

    function swapElements(element1, element2, options, callback) {
        validate.element(element1);
        validate.element(element2);
        if (typeof options === "function") {
            callback = options;
            options = {};
        }
        options = validate.options(options);
        callback = validate.callback(callback);

        if (element1._swapper || element2._swapper) {
            throw Error("One of the elements is currently being swapped");
        }

        if (!isInDOM(element1, true)) {
            throw Error("element1 must be in the DOM to be swapped");
        }

        element1._swapper = true;
        element2._swapper = true;
        removeNode(element2);

        performSwap(element1, element2, options, function () {
            element1._swapper = false;
            element2._swapper = false;
            callback();
        });
    }

    function performSwap(element1, element2, options, doneCallback) {
        if (options.transition === "instant") {
            insertAfter(element2, element1);
            removeNode(element1);
            doneCallback();
            return;
        }

        var transitionSettings = transitions[options.transition || defaultTransition],
            easing = options.easing || defaultEasing,
            duration = options.duration || 300;

        if (easing.substr(0, 13) !== "cubic-bezier(") {
            easing = easings[easing];
        }

        insertAfter(element2, element1);
        var initialStyles = getStyles(element1),
            targetStyles = getStyles(element2),
            element1InlineStyles = getStyles(element1, true),
            element2InlineStyles = getStyles(element2, true);

        adjustPositioning(element1, element2, initialStyles, targetStyles);

        if (transitionSettings[2]) {
            insertBefore(element2, element1);
        }

        element2.style.opacity = "0";
        resetStyles(element1, element2);

        setTimeout(function () {
            element2.style.opacity = targetStyles.opacity;
            applyInitialTransform(element1, element2, transitionSettings);

            setTimeout(function () {
                applyTransition(element1, element2, duration, easing);
                setTimeout(function () {
                    finalizeTransform(element1, element2, transitionSettings);
                    handleTransitionEnd(
                        element1,
                        element2,
                        initialStyles,
                        targetStyles,
                        transitionSettings,
                        duration,
                        function () {
                            removeNode(element1);
                            restoreStyles(element1, element2, duration, easing);
                            setTimeout(function () {
                                clearTransforms(element1, element2);
                                doneCallback();
                            }, 0);
                        }
                    );
                }, 0);
            }, 50);
        }, 0);
    }

    function adjustPositioning(element1, element2, initialStyles, targetStyles) {
        var rect = element1.getBoundingClientRect();
        if (initialStyles.display !== "none") {
            element2.style.position = "fixed";
            element2.style.top = rect.top + "px";
            element2.style.left = rect.left + "px";
        }
        element2.style.height = targetStyles.height || initialStyles.height;
        element2.style.width = targetStyles.width || initialStyles.width;
    }

    function clearTransforms(element1, element2) {
        setTransform(element1, "");
        setTransform(element2, "");
    }

    function applyInitialTransform(element1, element2, transitionSettings) {
        setTransform(element1, defaultTransform);
        setTransform(element2, transitionSettings[0].transform || defaultTransform);

        if (transitionSettings[0].fade) {
            element2.style.opacity = "0";
        }
        if (transitionSettings[1].fade) {
            element1.style.opacity = "1";
        }
    }

    function finalizeTransform(element1, element2, transitionSettings) {
        setTransform(element1, transitionSettings[1].transform || defaultTransform);
        setTransform(element2, defaultTransform);

        if (transitionSettings[0].fade) {
            element2.style.opacity = "1";
        }
        if (transitionSettings[1].fade) {
            element1.style.opacity = "0";
        }
    }

    function resetStyles(element1, element2) {
        setTransition(element1, "");
        setTransition(element2, "");
    }

    function applyTransition(element1, element2, duration, easing) {
        var transitionString =
            "transform " + duration / 1000 + "s " + easing + ",opacity " + duration / 1000 + "s " + easing;
        setTransition(element1, transitionString);
        setTransition(element2, transitionString);
    }

    function restoreStyles(element1, element2, duration, easing) {
        setTransition(element1, "");
        setTransition(element2, "");
    }

    function handleTransitionEnd(element1, element2, initialStyles, targetStyles, transitionSettings, duration, onComplete) {
        var transitionElement;
        if (shouldWaitForTransition(targetStyles, transitionSettings[0])) {
            transitionElement = element2;
            waitForTransitionEnd();
        } else if (shouldWaitForTransition(initialStyles, transitionSettings[1])) {
            transitionElement = element1;
            waitForTransitionEnd();
        } else {
            setTimeout(onComplete, duration);
        }

        function waitForTransitionEnd() {
            transitionElement.addEventListener("webkitTransitionEnd", onTransitionComplete, false);
            transitionElement.addEventListener("transitionend", onTransitionComplete, false);
        }

        function onTransitionComplete(event) {
            if (event && event.target === transitionElement) {
                transitionElement.removeEventListener("webkitTransitionEnd", onTransitionComplete);
                transitionElement.removeEventListener("transitionend", onTransitionComplete);
                onComplete();
            }
        }
    }

    function shouldWaitForTransition(styles, transition) {
        if (styles.display === "none") return false;
        if (transition.fade) return true;
        if (!transition.transform) return false;
        return transition.transform !== defaultTransform;
    }

    return swapElements;
})(
    Swapper._isNode,
    Swapper._isInDOM,
    Swapper._insertBefore,
    Swapper._insertAfter,
    Swapper._removeNode,
    Swapper._setTransform,
    Swapper._setTransition,
    Swapper._getStyles,
    Swapper._transitions,
    Swapper._easings,
    Swapper._validate,
    window,
    document
);

var Scrollable = (function () {
    function ScrollableInstance() {
        ScrollableInstance._enableScrolling.apply(this, arguments);
    }
    ScrollableInstance.node = function () {
        return ScrollableInstance._getScrollableNode.apply(this, arguments);
    };
    return ScrollableInstance;
})();

Scrollable._isDOMNode = (function (Node, HTMLElement) {
    return function (element) {
        if (!element) {
            return false;
        }
        try {
            return element instanceof Node || element instanceof HTMLElement;
        } catch (error) { }
        if (typeof element !== "object") {
            return false;
        }
        if (typeof element.nodeType !== "number") {
            return false;
        }
        if (typeof element.nodeName !== "string") {
            return false;
        }
        return true;
    };
})(Node, HTMLElement);

Scrollable._forEachInArray = (function (arrayForEach) {
    return function (array, callback, thisArg) {
        if (arrayForEach) {
            return arrayForEach.call(array, callback, thisArg);
        }
        for (var index = 0, length = array.length; index < length; index++) {
            if (index in array) {
                callback.call(thisArg, array[index], index, array);
            }
        }
    };
})(Array.prototype.forEach);

Scrollable._onReady = (function (document, window, forEachInArray) {
    var callbacks = [],
        isReady = false;
    initialize(onReady);

    return function (callback) {
        if (isReady) {
            callback();
        } else {
            callbacks.push(callback);
        }
    };

    function onReady() {
        if (isReady) {
            return;
        }
        isReady = true;
        forEachInArray(callbacks, function (callback) {
            setTimeout(callback, 0);
        });
    }

    function initialize(readyCallback) {
        if (document.readyState === "complete") {
            setTimeout(readyCallback, 0);
            return;
        }
        if (document.addEventListener) {
            document.addEventListener("DOMContentLoaded", readyCallback, false);
            window.addEventListener("load", readyCallback, false);
        } else {
            if (document.attachEvent) {
                document.attachEvent("onreadystatechange", readyCallback);
                window.attachEvent("onload", readyCallback);
            }
        }
    }
})(document, window, Scrollable._forEachInArray);

Scrollable._scrollWatcher = (function () {
    return scrollWatcher;

    function scrollWatcher(element) {
        var isScrolling = false,
            hasScrolled = false,
            previousScrollTop = element.scrollTop;

        element.addEventListener("touchstart", onTouchStart, { passive: true});
        element.addEventListener("touchmove", onTouchMove, { passive: true});
        element.addEventListener("touchcancel", onTouchCancel, { passive: true});
        element.addEventListener("touchend", onTouchEnd, { passive: true});
        element.addEventListener("scroll", onScroll, { passive: true});
        updateScrollingState();

        element._resetScrolling = resetScrolling;

        function updateScrollingState() {
            element._isScrolling = hasScrolled || isScrolling;
        }

        function resetScrolling() {
            hasScrolled = false;
            isScrolling = false;
            updateScrollingState();
        }

        function handleTouchEvent(event, minTouches, maxChangedTouches) {
            if (
                event.touches.length <= minTouches &&
                (typeof maxChangedTouches === "undefined" || event.changedTouches.length <= maxChangedTouches)
            ) {
                return false;
            }
            event.preventDefault();
            resetScrolling();
            return true;
        }

        function onTouchStart(event) {
            if (handleTouchEvent(event, 1)) {
                return;
            }
            resetScrolling();
        }

        function onTouchMove(event) {
            if (handleTouchEvent(event, 1)) {
                return;
            }
            isScrolling = true;
            previousScrollTop = element.scrollTop;
            updateScrollingState();
        }

        function onTouchCancel(event) {
            if (handleTouchEvent(event, 0, 1)) {
                return;
            }
            resetScrolling();
        }

        function onTouchEnd(event) {
            if (handleTouchEvent(event, 0, 1)) {
                return;
            }
            var scrollDistance;
            if (isScrolling) {
                scrollDistance = Math.abs(element.scrollTop - previousScrollTop);
                if (scrollDistance > 5) {
                    hasScrolled = true;
                }
                isScrolling = false;
                updateScrollingState();
            }
        }

        function onScroll() {
            if (!isScrolling && hasScrolled) {
                resetScrolling();
            }
        }
    }
})();

Scrollable._enableScrolling = (function (isDOMNode, onReady, forEachInArray, scrollWatcher, window, document) {
    return enableScrolling;

    function enableScrolling(element, useIScroll) {
        if (!isDOMNode(element)) {
            throw element + " is not a DOM element";
        }
        if (element._scrollable) {
            return;
        }
        element._scrollable = true;
        var iScrollInstance;

        element._scrollTop = function (scrollTop, callback) {
            if (typeof scrollTop === "undefined") {
                return iScrollInstance ? Math.max(parseInt(-iScrollInstance.y), 0) : element.scrollTop;
            }
            if (!iScrollInstance) {
                element.scrollTop = scrollTop;
                callback && callback();
                return;
            }
            onReady(function () {
                iScrollInstance.scrollTo(iScrollInstance.x, Math.min(-scrollTop, 0), 1);
                callback && callback();
            });
        };

        element._scrollLeft = function (scrollLeft) {
            if (typeof scrollLeft === "undefined") {
                return iScrollInstance ? Math.max(parseInt(-iScrollInstance.x), 0) : element.scrollLeft;
            }
            if (!iScrollInstance) {
                element.scrollLeft = scrollLeft;
                return;
            }
            onReady(function () {
                iScrollInstance.scrollTo(Math.min(-scrollLeft, 0), iScrollInstance.y, 1);
            });
        };

        element.style.overflow = "scroll";
        if (!useIScroll) {
            element.style["-webkit-overflow-scrolling"] = "touch";
            scrollWatcher(element);
            return;
        }
        setupIScroll(element, function (iScroll) {
            iScrollInstance = iScroll;
        });
    }

    function setupIScroll(element, callback) {
        element._iScroll = true;
        wrapChildrenInContainer(element);
        var scrollCallback = createScrollCallback(element);

        onReady(function () {
            var iScroll = new IScrollLibrary(element, {
                checkDOMChanges: true,
                useTransform: true,
                useTransition: true,
                hScrollbar: false,
                vScrollbar: false,
                bounce: true,
                onScrollMove: scrollCallback,
                onBeforeScrollEnd: scrollCallback,
                onScrollEnd: function () {
                    element._iScrolling = false;
                    scrollCallback();
                },
                onBeforeScrollStart: preventDefaultForInputs,
                onScrollStart: function () {
                    element._iScrolling = true;
                },
            });
            element._iScroll = iScroll;
            callback(iScroll);
        });
    }

    function wrapChildrenInContainer(element) {
        var container = document.createElement("div"),
            childNodes = Array.prototype.slice.call(element.childNodes || []);
        forEachInArray(childNodes, function (child) {
            var removedChild = element.removeChild(child);
            container.appendChild(removedChild);
        });
        element.appendChild(container);
        element.style.position = "relative";
        container.style["min-height"] = "100%";
        container.style["min-width"] = "100%";
    }

    function createScrollCallback(element) {
        var previousScrollTop, previousScrollLeft;
        return function () {
            var currentScrollTop = element._scrollTop(),
                currentScrollLeft = element._scrollLeft();
            if (currentScrollTop === previousScrollTop && currentScrollLeft === previousScrollLeft) {
                return;
            }
            previousScrollTop = currentScrollTop;
            previousScrollLeft = currentScrollLeft;
            dispatchScrollEvent(element);
        };
    }

    function dispatchScrollEvent(element) {
        if (element.dispatchEvent) {
            var scrollEvent = document.createEvent("MouseEvents");
            scrollEvent.initMouseEvent(
                "scroll",
                false,
                false,
                window,
                0,
                0,
                0,
                0,
                0,
                false,
                false,
                false,
                false,
                0,
                null
            );
            element.dispatchEvent(scrollEvent);
        }
    }

    function preventDefaultForInputs(event) {
        var target = event.target;
        while (target.nodeType !== 1) {
            target = target.parentNode;
        }
        if (
            target.tagName !== "SELECT" &&
            target.tagName !== "INPUT" &&
            target.tagName !== "TEXTAREA"
        ) {
            event.preventDefault();
        }
    }
})(
    Scrollable._isDOMNode,
    Scrollable._onReady,
    Scrollable._forEachInArray,
    Scrollable._scrollWatcher,
    window,
    document
);

Scrollable._getScrollableNode = (function (isDOMNode) {
    return function (element) {
        if (isDOMNode(element) && element._iScroll) {
            return element.childNodes[0];
        } else {
            return element;
        }
    };
})(Scrollable._isDOMNode);

export { Swapper, Scrollable };
